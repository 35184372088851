import Embed from "../components/Embed"
import {useEffect, useState} from "react";

export default function Electronics() {
    const pcbSrc = "https://viewer.altium.com/client/static/js/embed.js"
    let p1 = `To the right you can view the schematics, PCB layout, and BOM for the robots motor controller.`

    let p2 = `` // I have to think about this one...

    useEffect(()=>{
        if(!window.location.hash) {
            window.location = window.location + '#loaded';
            window.location.reload();
        }
    },[])

    return (
        <Embed
            src={pcbSrc}
            isAltium={true}
            content={[p1, p2]}
            weakTitle="Electronic"
            strongTitle="Design"
            subheading="Motor Controller PCB Design"
            instructions="You can select different views of the system on the tabs along the top. In the PCB viewer
            you can enable and disable different layers of the PCB file to view components on the top and bottom."
        ></Embed>
    );
}
