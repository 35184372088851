import Tree from "react-d3-tree";
import data from "../media/trackedFiles.json";
import "./FileTree.css"
import useWindowSize from "./UseWindowSize";

export default function FileTree(props) {
    const size = useWindowSize();

    return (
        <div className="App">
            <div id="treeWrapper" style={{ width: "auto", height: "90vh" }}>
                <Tree
                    data={data}
                    initialDepth={1}
                    translate={{ x: Math.round(size.width/50), y: Math.round(size.height/2.5) }}
                    zoom={0.85}
                    separation={{ siblings: 0.5, nonSiblings: 10 }}
                    shouldCollapseNeighborNodes= {true}
                    rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                    pathFunc={'diagonal'}
                    depthFactor = {300}
                    orientation={'horizontal'}
                    onNodeClick={props.parentFunction}
                    onLinkClick={(...args) => {
                        console.log("onLinkClick");
                        console.log(args);
                    }}
                />
            </div>
        </div>
    );
}
