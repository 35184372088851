import {Card, Button, Jumbotron} from 'react-bootstrap';

const cardStyle = {
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
}

const cardTitleStyle = {
    textAlign: "left",
    paddingLeft: "1rem",
    paddingTop: "5px",
}

const cardHeaderStyle = {
    background: `linear-gradient(65deg, rgba(176,196,222,1) 35%, rgba(255,255,224,1) 90%)`,
}

const cardBodyStyle = {
    background: "snow"
}

export default function FileCard(props){

    return(
        <Card style={cardStyle}>
            <Card.Header style={cardHeaderStyle}>
                <Card.Title style={cardTitleStyle}>
                    {props.name}
                </Card.Title>
            </Card.Header>
            <Card.Body style={cardBodyStyle}>
                    {props.content}
            </Card.Body>
        </Card>
    )
}
