import connector from "../images/batteryConnector.png";
import charging from "../images/batteryCharging.png";
import config from "../images/chargerConfig.png";
import {Card, Col, Row, Jumbotron, Alert} from "react-bootstrap";
import React from "react";
import ReactMarkdown from "react-markdown";

let titleStyle = {
    background: `linear-gradient(65deg, rgba(176,196,222,1) 35%, rgba(255,255,224,1) 90%)`,
    paddingTop: "1.5rem",
    paddingBottom: "1rem",
    width: "span",
};


const imgStyle = {
    maxHeight:"40vh",
    width:"auto",
    padding:"1rem"
}

const md1 =`
## Battery Charging
`

export default function Charging(){
    return(
        <div className='d-flex justify-content-center'>
            <Col lg={6}>
                <Alert variant="danger">
                    <ReactMarkdown>
                        {
                            `
* __Always ensure that the robot is powered off before charging.__ The robot must not be plugged into the wall, 
nor should the red power LED or cooling fan be on. 
* Do not leave a charging battery un-attended!
* You should always charge batteries in a fireproof location with good ventilation.
`
                        }
                    </ReactMarkdown>
                </Alert>
                <Card>
                    <Card.Title>
                        <Jumbotron fluid style={titleStyle}>
                            <h3 style={{marginLeft:"1rem"}}>
                                Battery<b>Charging</b>
                            </h3>
                        </Jumbotron>
                    </Card.Title>
                    <Card.Body style={{marginTop:"-2rem"}}>

                        <p>
                            The robot features an external battery charger and custom charging lead.
                            The external connectors contactors connect directly to the robots battery.
                            Care must be taken to ensure these are never shorted together.<br/>
                            Connect the custom charge to the robot, and then to the battery chargers primary leads, and balancing leads.
                        </p>
                        <Row className="justify-content-md-center">
                            <img style={imgStyle} src={connector}/>
                            <img style={imgStyle} src={charging}/>

                        </Row >
                        <p>
                            Once connected, ensure that battery charger is configured for 4 cell LiPo batteries
                            (nominally 14.8 volts), and that the charge current is set to 3.0 amps.
                            If the battery voltage is below its minimum voltage of 9.0 volts, let the battery sit for
                            a few hours or overnight. If the battery's cells still have not stabilized and the charger
                            will still not allow you to charge the battery - it still possible to recover the battery using
                            low, constant-current charging modes.
                        </p>
                        <Row className="justify-content-md-center">
                            <img style={imgStyle} src={config}/>
                        </Row>
                        <p>
                            Never leave charging batteries unattended. The robot should take about one hour to charge to
                            its maximum voltage of 16.8V.

                        </p>



                    </Card.Body>
                </Card>
            </Col>
        </div>
    )
}