import FileTree from "../components/FileTree";
import FileCard from "../components/FileCard";
import {Row, Col, Container} from 'react-bootstrap';
import {useState} from 'react';
import ReactMarkdown from "react-markdown";

export default function Software() {
    const [name, setName] = useState(<h3>Select a node</h3>);
    const [path, setPath] = useState("File path");
    const defaultMarkdown =
`Click any file or directory on the node tree to find out more about it.
You can move the node tree around by pressing, holding, and dragging it.
If a folder contains a "README.md" file it will be displayed here.
Likewise, if a file contains any special comments, it will also be displayed here.
A quick summary of the files in the project are shown below.
* __main.py__:
This is the python file that runs the GUI responsible for controlling the robot.
* __lipo.py__:
This is file contains python methods to help main.py calculate battery depletion from analog voltages.  
* __gst-absolutetimestamps__:
This is a submodule of our git repository and helps extract precise timestamps from our gstreamer pipeline.
* __opencv_source__:
This is another submodule used to help import a gstreamer compatible build of opencv into our project. 
* __pid_tuning__:
This folder contains a few python routines to help remote tune the robots PID parameters.
* __robot_software__:
This folder contains all software run on the robot itself.
* __shell_commands__:
This folder contains a variety of linux bash scripts and python scripts used by main.py to control the robot.
* __trackball__:
This folder contains all of the scripts and methods required to connect to the trackball.
`;
    const [content, setContent] = useState(<ReactMarkdown>{defaultMarkdown}</ReactMarkdown>);

    function handleCallback(childNode, childEvt) {
        setName(childNode['data']['name'])
        setPath(childNode['data']['path'].replace("public/", ""))
        let isDir = (('children' in childNode['data']));

        let filePath = childNode['data']['path']
        filePath = filePath.replace("public", "");
        if (!isDir) {
            console.log(filePath);
            let extension = childNode['data']['name'].split('.').pop();
            if (extension === "png") {
                //handle image
                let img = <img style={{width: "100%"}} alt="" src={(filePath)}/>
                setContent(img);
            } else {

                fetch(filePath)
                    .then((r) => r.text())
                    .then(text => {
                        let start = text.indexOf("!!!");
                        let stop = text.indexOf("$$$");
                        if (start !== -1 & stop !== -1) {
                            let mySubString = text.substring(
                                text.indexOf("!!!") + 3,
                                text.indexOf("$$$")
                            )
                            setContent(<ReactMarkdown>{mySubString}</ReactMarkdown>);
                        } else {
                            setContent("No valid text in file");
                        }
                    })
            }
        } else {
            let readmePath = filePath + "/README.md";
            console.log(readmePath);
            try {
                fetch(readmePath)
                    .then((r) => r.text())
                    .then(text => {
                        fetch(filePath)
                            .then((r) => r.text())
                            .then(dumbText => {
                                if(text !== dumbText){
                                    setContent(<ReactMarkdown>{text}</ReactMarkdown>);
                                }
                            })
                        setContent("Could not find readme.")
                    })
            } catch (err) {
                setContent("Could not find readme.")
            }

        }


    }

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col xs={12} md={6} lg={6} class="my-auto">
                        <FileCard
                            name={name}
                            content={content}
                            path={path}
                        />
                    </Col>
                    <Col>
                        <FileTree parentFunction={handleCallback}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
