import Embed from "../components/Embed"

export default function Mechanics() {
    const pcbSrc = "https://myhub.autodesk360.com/ue2c021aa/shares/public/SH56a43QTfd62c1cd968197a33b668dcad55?mode=embed"

    let p1 = `To the right you can view the 3D CAD files for the robots mechanics.`

    let p2 = `` // I have to think about this one...

    return (
        <div>
            <Embed
                src={pcbSrc}
                isAltium={false}
                content={[p1, p2]}
                weakTitle="Mechanical"
                strongTitle="Design"
                subheading="Robot 3D Assembly"
                instructions="Select the box in the top left corner to view the component tree,
                isolate sub-components, and select views of the model."
            ></Embed>
        </div>
    );
}
