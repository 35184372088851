import {Card, Col, Row, Jumbotron, Alert} from "react-bootstrap";
import React from "react";

const titleStyle = {
    background: `linear-gradient(65deg, rgba(176,196,222,1) 35%, rgba(255,255,224,1) 90%)`,
    paddingTop: "1.5rem",
    paddingBottom: "1rem",
    width: "span",
};

const iframeStyle = {

    width: "100%",
    height: "70vh",
}


const embedId = 'rk0CBRMFh6Q'
export default function Usage(){
    return(
        <div className='d-flex justify-content-center'>
            <Col lg={6}>
                <Card>
                    <Card.Title>
                        <Jumbotron fluid style={titleStyle}>
                            <h3 style={{marginLeft:"1rem"}}>
                                Usage<b>Overview</b>
                            </h3>
                        </Jumbotron>
                    </Card.Title>
                    <Card.Body style={{marginTop:"-2rem"}}>
                        <div className="video-responsive">
                            <iframe
                                style={iframeStyle}
                                src={`https://www.youtube.com/embed/${embedId}`}
                                allowFullScreen="true"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                frameBorder="0"
                            />
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </div>
    )
}