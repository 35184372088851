import React from "react";
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";


const Header = () => {
    let navbarStyle = {
        width: "100%",
        whiteSpace: "nowrap",
        height: "7vh",
        marginBottom: "10px",
        background: `linear-gradient(65deg, rgba(176,196,222,1) 35%, rgba(255,255,224,1) 90%)`,
    };

    return (
        <Navbar style={navbarStyle}>
            <Navbar.Brand href="/">
                <img
                    alt=""
                    src={"https://vignette.wikia.nocookie.net/rickandmorty/images/7/77/Butter_Robot.png/revision/latest/scale-to-width-down/310?cb=20160910011723"}
                    width="50"
                    height="50"
                />
                FPVBot<b>Docs</b>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">

                    <NavDropdown title="Mechanics" id="basic-nav-dropdown">
                        <NavDropdown.Item>
                            <LinkContainer to="/mech">
                                <Nav.Link>3D CAD</Nav.Link>
                            </LinkContainer>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <LinkContainer to="/assy">
                                <Nav.Link>Assembly</Nav.Link>
                            </LinkContainer>
                        </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Electronics" id="basic-nav-dropdown">
                        <NavDropdown.Item>
                            <LinkContainer to="/charge">
                                <Nav.Link>Battery Charging</Nav.Link>
                            </LinkContainer>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <LinkContainer to="/elec">
                                <Nav.Link>PCB CAD Files</Nav.Link>
                            </LinkContainer>
                        </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Software" id="basic-nav-dropdown">
                        <NavDropdown.Item>
                            <LinkContainer to="/soft">
                                <Nav.Link>File Tree</Nav.Link>
                            </LinkContainer>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <LinkContainer to="/usage">
                                <Nav.Link>Usage Overview</Nav.Link>
                            </LinkContainer>
                        </NavDropdown.Item>
                    </NavDropdown>

                </Nav>
            </Navbar.Collapse>
        </Navbar>


    );
};

export default Header;
