import React, {useState} from "react";
import {Jumbotron, Row, Col, Card, Button, Alert} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Embed(props) {

    let iframeStyle = {
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        width: "100%",
        height: "90vh",
    }

    const altiumStyle= {
        borderRadius: "0px 0px 4px 4px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "rgb(241, 241, 241)",
        overflow: "hidden",
        maxWidth: "100%",
        maxHeight: "100%",
        height:"90vh",
        boxSizing: "border-box",
    }

    let titleStyle = {
        background: `linear-gradient(65deg, rgba(176,196,222,1) 35%, rgba(255,255,224,1) 90%)`,
        paddingTop: "1.5rem",
        paddingBottom: "1rem",
        width: "span",
    };


    function renderContent() {
        return props.content.map((item, index) => {
            return (
                <p style={{textAlign: "left"}}>{item}</p>
            );
        });
    }

    function renderRemoteContent() {
        if (props.isAltium==false) {
            return (
                <iframe
                    src={props.src}
                    style={iframeStyle}
                    allowFullScreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    frameBorder="0"
                />
            );
        }else{
            return (
                <div class="altium-ecad-viewer" data-project-src="332e8440-a34b-4c0b-acee-83728bf30740"
                         style={altiumStyle}>
                </div>
            );
        }
    }

return (
    <Row className="m-auto align-self-center">
        <Col xs={12} sm={3}>
            <br/>

            <Card>
            <Card.Title>
                <Jumbotron fluid style={titleStyle}>
                    <h3>
                        {props.weakTitle} <b>{props.strongTitle}</b>
                    </h3>
                </Jumbotron>
                <h3>{props.subheading}</h3>
            </Card.Title>
                <Card.Body>
                    {renderContent()}
                    <p style={{textAlign: "left"}}><i>{props.instructions}</i></p>
                </Card.Body>
            </Card>
            <br/>
            <Alert variant="warning">Please allow a minute for the content to load. If there are issues loading, try refreshing the page.</Alert>
            <br/>
        </Col>
        <Col xs={12} sm={9}>
            {renderRemoteContent()}
        </Col>
    </Row>
)
}
