import Software from "./pages/Software"
import Mechanics from "./pages/Mechanics"
import Electronics from "./pages/Electronics";
import Charging from "./pages/Charging";
import Header from "./components/Header"
import Assembly from "./components/Assembly";
import Usage from "./pages/Usage";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const jumboStyle= {
    textAlign: "center",
    textColor:'white',
    align: "center",
    color: "black",
    background: `linear-gradient(65deg, rgba(176,196,222,1) 35%, rgba(255,255,224,1) 90%)`,
    paddingBottom: "1rem",
    paddingRight: "2rem",
    paddingLeft: "2rem",
    paddingTop: "2rem",
}


export default function App() {

    return (
        <div className="App" style={{height:"100vh", background:'rgb(246,246,248)', paddingTop:"-2vh"}}>
            <Router>
                <Header />
                <Switch>
                    <Route exact path="/" component={Software} />
                    <Route path="/mech" component={Mechanics} />
                    <Route path="/soft" component={Software} />
                    <Route path="/elec" component={Electronics}/>
                    <Route path="/charge" component={Charging}/>
                    <Route path="/assy" component={Assembly}/>
                    <Route path="/usage" component={Usage}/>
                </Switch>
            </Router>
        </div>
    );
}
